//
// Reverts
//
// Place here all reverts to Bootstrap's reboot.scss file

button {
    border-radius: revert-layer;
    margin: revert-layer;
}

table {
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: revert-layer;
        border-style: revert-layer;
        border-width: revert-layer;
    }
}

a {
    color: revert-layer;
}

ol, ul {
    padding: revert-layer;
    margin: revert-layer;
}
