$primaryColor: #38BEB5 !default;
$primaryLight: #5FECC8 !default;
$primaryDarkColor: #3C6D79 !default;
$primaryDarkerColor: #2f4858 !default;
$orsoLightBg: #DCF4F0 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

$secondaryColor: #7399C4 !default;
$secondaryLightColor: #f1faff !default;
$secondaryDarkColor: #5279a2 !default;
$secondaryDarkerColor: #305b82 !default;
$secondaryTextColor: #ffffff !default;

@import '../_variables';
@import './_fonts';
@import 'assets/theme-base/_components';
@import '../_extensions';
