//
// Pages: Login & Register
//


@include dark-mode() {
    .login-card-body,
    .register-card-body {
        background-color: $dark;
        border-color: $gray-600;
        color: $white;
    }
    .login-logo,
    .register-logo {
        a {
            color: $white;
        }
    }
}
