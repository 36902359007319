
.pointer {
    width: 200px;
    height: 2rem;
    position: relative;
    background: $gray-300;
    color: $gray-600;
    transition-property: background;
    transition-duration: 0.4s;

    &.active {
        background: $primary;
        color: $gray-300;

        &:before {
            border-left: 1rem solid $primary;
        }
    }

    &:hover:not(.active) {
        background: $gray-300;
        transition-property: background;
        transition-duration: 0.4s;
        color: $primary;

        &:before {
            border-left: 1rem solid $gray-300;
            transition-property: border-color;
            transition-duration: 0.4s;
        }
    }
}

.pointer:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 1rem solid $gray-300;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
}

.pointer:before {
    content: '';
    position: absolute;
    right: -1rem;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 1rem solid $gray-300;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    transition-property: border-color;
    transition-duration: 0.4s;
}
